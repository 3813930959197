<template>
  <img style="height: 30px;"
       :src="appLogoImage"
  />
</template>
<script>
import { $themeConfig } from '@themeConfig'

export default {
  setup() {
    // App Name
    const { appLogoImage } = $themeConfig.app
    return {
      appLogoImage,
    }
  },
}
</script>
